import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../media/logo.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function MyNav(props) {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="fixed-top">
      
        <Navbar.Brand href="#"><img id="logo" src={logo} alt="Empistos.co"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto navbar-nav container mt-2 mt-lg-0 justify-content-end">
      
                <ul className="navbar-nav container mr-auto mt-2 mt-lg-0 justify-content-end">
                    <NavLink to='/'>
                    <li className="nav-item nav-link">Home</li>
                    </NavLink>

                    <NavLink to='/about'>
                    <li className="nav-item nav-link">About Us</li>
                    </NavLink>

                    <NavLink to='/consulting'>
                    <li className="nav-item nav-link">Consulting</li>
                    </NavLink>

                    <NavLink to='/analytics'>
                    <li className="nav-item nav-link">Analytics</li>
                    </NavLink>

                    {/* <NavLink to='/integration'>
                    <li className="nav-item nav-link">Integrations</li>
                    </NavLink> */}
                    
                    <li className="nav-item" id="contact">
                    <button className="btn btn-primary text-light" data-toggle="modal" data-target="#contactModal" onClick={props.handleShow}>Get In Touch</button>
                    </li>
                 </ul>
        </Nav>
        </Navbar.Collapse>
        
    </Navbar>
  );
}

export default MyNav;