import React from 'react';
import AboutImage from '../media/about.jpg';

function About () {
    let styles = {
        backgroundImage: `url(${AboutImage})`
    }
    return (
        <div>
            <section class="banner-section pt-4 pt-md-11">
                <div class="banner row" style={styles}>
                    <div class="col-12 align-self-center my-text-shadow text-center">
                        <h1 class="display-4 text-center">
                            émpistos | έμπιστος
                        </h1><br/>
                        <h3 class="text-dark"><em>(Greek) Adjective.</em> Trusted, Reliable.</h3>
                    </div>
                </div>
            </section>

            <section class="content-section py-8 py-md-11 border-bottom">
                <div class="container">
                    <h3 class="text-center font-weight-light">About Us</h3>
                    <br/>
                    <div class="row">
                        <div class="col-12">
                            <h3>Our Philosophy</h3>
                            <p class="text-dark">
                                We aim to be your advisors in making the most out of the Workday platform. Therefore, your trust is our priority and most valued compensation. 
                            </p>
                        </div>
                        <br/><br/><br/><br/><br/>
                        <div class="col-12">
                            <h3>Our Team</h3>
                            <p class="text-dark">
                                We consist of former Workday employees and delivery assurance experts. We have worked with Fortune 500 companies and have also partnered with some of the largest implementation firms to help clients across all industries, including retail, technology, healthcare, etc...
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About;