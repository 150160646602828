import React from 'react';
import Banner from '../media/index.jpg';
import '../App.css';
import Image3 from '../media/img3.jpg';
import Image2 from '../media/img2.jpg';
import Integrations from '../media/integrations.png';



function Home (props) {
    let styles = {
        backgroundImage: `url(${Banner})`
    }
    return (
        <div id="main-content">
            {console.log( props)}
            <section className="banner-section pt-4 pt-md-11">
            <div className="banner" style={styles}>
                    <br></br>
                    <div className="h1 font-weight-light text-center my-text-shadow">
                    Welcome to Empistos.
                    </div>
                    <div className="h3 font-weight-light text-center my-text-shadow">
                    Trusted Advisor to Your Workday® Journey.
                    </div>
                    <p></p>
                    <div className="align-items-center text-center">
                    <button className="btn btn-lg btn-primary shadown lift mr-1 col-12 col-md-5 col-lg-5" data-toggle="modal" data-target="#contactModal" onClick = {props.handleShow}>Get in Touch</button>
                    </div>
            </div>
            </section>

            <section className="content-section py-8 py-md-11 border-bottom">
            <div className="container">
                <div className="row">
                <div className="col-12 col-md-4">
                    <div className="icon text-primary mb-3"></div>
                    <h3>Certified Industry Experts</h3>
                    <p className="text-muted mb-6 mb-md-0">
                    With years of Workday and HR Operational experience, we have helped countless clients directly as well as performed best practice reviews on other consultants work.
                    </p>
                </div>

                <div className="col-12 col-md-4">
                    <div className="icon text-primary mb-3"></div>
                    <h3>Business Focused</h3>
                    <p className="text-muted mb-6 mb-md-0">
                    We are not just IT folks. As true consultants, we understand the business needs of an HR operations across many industries and company sizes.
                    </p>
                </div>

                <div className="col-12 col-md-4">
                    <div className="icon text-primary mb-3"></div>
                    <h3>Workday® Made Easy</h3>
                    <p className="text-muted mb-6 mb-md-0">
                    To empower your business operations, we will ensure that your Workday system is configured in the most sustainable and intuitive way possible.
                    </p>
                </div>
                
                </div>
            </div>
            </section>

            <section className="content-section bg-gradient-light">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                
                    <div className="col-12 col-md-7 col-lg-6">
                    <h1 className="text-primary">Functional Configuration</h1>
                    <h3 className="my-sub-header">System design with deep business understanding.</h3>
                    </div>

                <div className="col-12 col-md-5 col-lg-6">
                    <div className="w-md-150 w-lg-130 position-relative">
                    <img src={Image3} alt="Human Resources Management" className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" /> 
                    </div>
                </div>
                </div>
            </div>
            </section>

            <section className="content-section bg-gradient-light">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-7 col-lg-6">
                    <h1 className="text-primary">Advanced Reporting and Analytics</h1>
                    <h3 className="my-sub-header">Intuitive dashboard design for a flexible audience.</h3>
                    </div>
                <div className="col-12 col-md-5 col-lg-6">
                    <div className="w-md-150 w-lg-130 position-relative">
                    <img src={Image2} alt="Advanced Reporting and Analytics" className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" /> 
                    </div>
                </div>
                </div>
            </div>
            </section>

            <section className="content-section bg-gradient-light">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-7 col-lg-6">
                    <h1 className="text-primary">Integrations Platform</h1>
                    <h3 className="my-sub-header">Seamless and maintenable without the fuss of WD Studio.</h3>
                    </div>
                <div className="col-12 col-md-5 col-lg-6">
                    <div className="w-md-150 w-lg-130 position-relative">
                    <img src={Integrations} alt="Integrations" className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" /> 
                    </div>
                </div>
                </div>
            </div>
            </section>
    </div>
    )
}

export default Home;