import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import validateAll from '../validation';
import sendMessage from '../sendMessage';

function MyModal (props) {
    const [errors, setErrors] = React.useState(true);
    const handleSubmit = (e) => {
        e.preventDefault();
        let email = e.target.emailInput.value,
            name = e.target.nameInput.value,
            message = e.target.messageInput.value;
        
        setErrors(validateAll(email, name, message));

        if (!errors.length) {
            let data = {
                email: email,
                name: name,
                message: message
            }
            sendMessage(data);
            props.handleClose();

        }
        

    }
    return (
        <Modal show={props.show} onHide={props.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {errors.length ? (<div className="alert alert-danger">{errors.map((err, key)=>(<p key={key}>{err}</p>))}</div>) : null}
            <form id="contactForm" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label for="emailInput">Email Address <span className="text-danger">*</span></label>
                    <input type="email" id="emailInput" className="form-control" required={true} placeholder="name@example.com" />
                </div>

                <div className="form-group">
                    <label for="nameInput">Your Name <span className="text-danger">*</span></label>
                    <input type="text" id="nameInput" className="form-control" required={true} placeholder="Your Name" />
                </div>

                <div className="form-group">
                    <label for="messageInput">Inquiry <span className="text-danger">*</span></label>
                    <textarea id="messageInput" className="form-control" required={true} placeholder="What brought you here?" rows="3"></textarea>
                </div>
                <input className="btn btn-lg btn-primary" type="submit" value="Submit" />
            </form>
        </Modal.Body>
      </Modal>
    )
}

export default MyModal;