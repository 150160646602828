import React from 'react';
import NotFound from '../media/not-found.jpg';

function PageNotFound (){
    return (
        <div id="main-content"> 

        <div className="container">
        <br/><br/>
        <div className="display-3">Oops, Page Not Found!</div>
        <section>
            <img src={NotFound}/>
        </section>
        </div>
        </div>
    
    )
}

export default PageNotFound;