import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MyNav from './components/myNav';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Consulting from './components/Consulting';
import Analytics from './components/Analytics';
import PageNotFound from './components/PageNotFound';
// import Integration from './components/Integration';
import MyModal from './components/myModal';

function App() {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    
    <BrowserRouter>
      <MyNav handleShow={handleShow} />
      <Switch>
        <Route path='/' exact render={(props) => <Home {...props} handleShow={handleShow} />} handleShow={handleShow}></Route> 
        <Route path='/about' exact component = {About}></Route>
        <Route path='/consulting' exact component = {Consulting}></Route>
        <Route path='/analytics' exact component = {Analytics}></Route>
        <Route path='/' component = {PageNotFound}></Route>
        {/* <Route path='/integration' exact component = {Integration}></Route> */}
      </Switch>
      <MyModal show ={show} handleClose={handleClose}/>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
