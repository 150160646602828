import React from 'react';

function Footer() {
  return (
      <React.Fragment>
        <div className="footer-copyright text-center py-3">Workday® is the registered trademark of Workday, Inc. Empistos, LLC is not an affiliate of Workday, Inc. Workday, Inc. does not endorse Empistos, LLC, it\'s website, products, or services.</div>
        <div className="container text-center">
        <a className="text-black-50" href="https://www.freepik.com/free-photos-vectors/infographic">Infographic vector created by pch.vector - www.freepik.com</a> | 
        <a className="text-black-50" href="https://www.freepik.com/free-photos-vectors/people">People vector created by pch.vector - www.freepik.com</a>    
        <a className="text-black-50" href="https://www.freepik.com/free-photos-vectors/banner">Banner vector created by katemangostar - www.freepik.com</a>
        </div>
    </React.Fragment>
  );
}

export default Footer;