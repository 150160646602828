function validateEmail (email) {

    if (email && email.trim() && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
    {
        return (true)
    }
        return (false)
}

function validateName (name) {
    if (name && name.trim()) {
        return true;
    }
    return false;
}

function validateMessage (message) {
    if (message && message.trim()) {
        return true;
    }
    return false;   
}

function validateAll(email, name, message) {
    let errors = [];
    if (!validateEmail(email)) {
        errors.push('Please enter a valid E-Mail.')   
    }
    if (!validateName(name)) {
        errors.push('Please enter your Name.')   
    }
    if (!validateMessage(message)) {
        errors.push('Please enter your Inquiry.')   
    }
    return errors;
}

export default validateAll;