import React from 'react';
import AnalyticsImage from '../media/analytics.jpg';

function Analytics () {
    let styles = {
        backgroundImage: `url(${AnalyticsImage})`
    }
    return (
        <div>
            <section class="banner-section pt-4 pt-md-11">
                <div class="banner row" style={styles}>
                    <div class="col-12 align-self-center my-text-shadow text-center">
                        <h1 class="display-4 text-center">
                            Analytics is Tough. But We're Here to Make It Easy.
                        </h1>
                        <h3 class="text-dark">To best leverage WD data, we have collaborated with teams ranging from HR Operations to Executives to Business Intelligence.</h3>
                    </div>
                </div>
            </section>

            <section class="content-section py-8 py-md-11 border-bottom">
                <div class="container">
                    <h3 class="text-center">Specialities:</h3>
                    <br/>
                    <div class="row">
                        <div class="col-12">
                            <h3>Operational Reporting</h3>
                            <p class="text-muted">
                                Some clients may find it difficult to carry out their daily tasks in WD, whether it is annual compensation planning or interview scheduling for candidates. Often times, these tasks can be easily handled with some WD advanced reporting at your fingertips.
                            </p>
                        </div>

                        <div class="col-12">
                            <h3>Executive Dashboards</h3>
                            <p class="text-muted">
                                Dashboards are a critical way to gain visibility into your teams performance as well as capture insight across the employee population. Furthermore, it is a great way to empower your executive team. 
                            </p>
                        </div>

                        <div class="col-12">
                            <h3>Cross Platform Business Intelligence</h3>
                            <p class="text-muted">
                                Whether it is through integrations or Workday's next generation Prism Analytics, we are here to bridge the gap between your different enterprise systems.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Analytics;