const firebase = require('firebase');
require('firebase/functions');
const firebaseConfig = {
    apiKey: "AIzaSyAGolBsvpd3Lui-K7PlrK4aqjEPVKDLtLs",
    authDomain: "empistos-website.firebaseapp.com",
    databaseURL: "https://empistos-website.firebaseio.com",
    projectId: "empistos-website",
    storageBucket: "empistos-website.appspot.com",
    messagingSenderId: "579208404557",
    appId: "1:579208404557:web:3c218638b8c8e1b3a10604",
    measurementId: "G-HTG71ETKRW"
  };

firebase.initializeApp(firebaseConfig);
  
  // Initialize Cloud Functions through Firebase
  var functions = firebase.functions();

export default async function sendMessage(data) {
    var addMessage = firebase.functions().httpsCallable('sendMessage');
    addMessage(data).then(function(result) {
    // Read result of the Cloud Function.
    return ('Message Sent!');

}).catch(function(error) {
    // Getting the Error details.
    var code = error.code;
    var message = error.message;

    return (`${code}: ${message}`);
  });
}