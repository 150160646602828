import React from 'react';
import ConsultingImage from '../media/business.jpg';

function Consulting () {
    let styles = {
        backgroundImage: `url(${ConsultingImage})`
    }
    return (
        <div>
            <section class="banner-section pt-4 pt-md-11">
                <div class="banner row" style={styles}>
                    <div class="col-12 align-self-center my-text-shadow text-center">
                        <h1 class="display-4 text-center">
                            We are experts in all major Workday HCM modules.
                        </h1>
                        <h3 class="text-dark">We are not just IT folks. We are also HR Management and Transformation experts.</h3>
                    </div>
                </div>
            </section>

            <section class="content-section py-8 py-md-11 border-bottom">
                <div class="container">
                    <h3 class="text-center">Just Some of Our Client Success Stories:</h3>
                    <br />
                    <div class="row">
                        <div class="col-12">
                            <h3>High-Volume Recruiting</h3>
                            <p class="text-muted">
                                Client had over 3 million candidates a year and is Workday's largest recruiting client to-date. We designed a recruiting solution that was automated to provided frictionless candidate and recruiter experience. Performance latency was minimized.
                            </p>
                        </div>

                        <div class="col-12">
                            <h3>Global Merger and Acquisition</h3>
                            <p class="text-muted">
                                Client had grown via acquisitions over the past decade with segmented HR processes in different countries. We helped simplify and align their global HR policies and redesigned their Annual Compensation Review process from beginning to end.
                            </p>
                        </div>

                        <div class="col-12">
                            <h3>Workday® Re-done</h3>
                            <p class="text-muted">
                                An early adopter of Workday, client has had challenges keeping up with new updates and best practices. We reviewed all HCM modules and provided a strategic roadmap in optimizing their system.
                            </p>
                        </div>
          
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Consulting;